import { DatadogLoggingService } from '@edx/frontend-logging';
import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';
import NotificationsTray from '@edx/frontend-plugin-notifications';

const config = {
  ...process.env,
  loggingService: DatadogLoggingService,
  pluginSlots: {
    notifications_tray: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'notification_tray',
            type: DIRECT_PLUGIN,
            RenderWidget: NotificationsTray,
          },
        }
      ]
    }
  }
};

export default config;
